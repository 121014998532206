import React from "react";

import { appURLs } from "@/app/app-urls.mjs";
import * as SOON from "@/game-cs2-marketing/constants/comingSoon.mjs";
import PROFILE_LINKS from "@/game-cs2-marketing/constants/profile-links.mjs";
import { GAME_SYMBOL_CS2 } from "@/game-csgo/definition-symbol.mjs";
import ComingSoon from "@/shared/ComingSoon.jsx";

const TITLE = [
  "common:navigation.cs2TwoDimensionalMatchReplay",
  "CS2 2D Match Replay",
];
const DESCRIPTION = [
  "",
  "Relive every moment of your CS2 competitive matches with our advanced Match Replay feature. Explore 2D replays with individual player stats, game events, loadout details, health and armor stats, money and much more to sharpen your strategy!",
];

function ProfileReplay() {
  return (
    <ComingSoon
      gameSymbol={GAME_SYMBOL_CS2}
      pageTitle={TITLE}
      pageLinks={PROFILE_LINKS}
      splashImage={`${appURLs.CDN}/blitz/cs2/comingSoon/cs2-soon-profile-weapons.webp`}
      splashTitle={SOON.TITLE}
      splashDecription={SOON.DESCRIPTION}
      footerTitle={SOON.FOOTER_TITLE}
      footerDescription={SOON.FOOTER_DESC}
    />
  );
}

export function meta() {
  return {
    title: TITLE,
    description: DESCRIPTION,
    subtitle: true,
  };
}

export default ProfileReplay;
